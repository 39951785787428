@media (max-width: 37.4rem) {
  .RunbookSnapshotInfo_hide-sm__2Iqf5 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .RunbookSnapshotInfo_visible-sm__-2F7H {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .RunbookSnapshotInfo_hide-md__1SuJS {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .RunbookSnapshotInfo_visible-md__BUHWP {
    display: none !important;
  }
}
.RunbookSnapshotInfo_container__17Bk1 {
  margin: 1rem 0 0 0;
}
.RunbookSnapshotInfo_noReleases__3ibBe {
  height: auto;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #9e9e9e;
}
.RunbookSnapshotInfo_releaseVersionLink__2kV7C {
  font-size: 1.125rem;
  margin: 0;
  padding: 0;
}
.RunbookSnapshotInfo_releaseDetailsLayout__2U_sC {
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
}
.RunbookSnapshotInfo_releaseDetailsLayoutContent__3JvId {
  border-right: 0.0625rem solid #cccccc;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.RunbookSnapshotInfo_releaseDetailsSidebarContent__19iwa {
  max-width: 260px;
  flex-grow: 1;
}
.RunbookSnapshotInfo_subTitle__35F7- {
  color: #9e9e9e;
}
.RunbookSnapshotInfo_releaseHeader__2P3EC {
  font-size: 0.875rem;
}
.RunbookSnapshotInfo_releaseHeaderVersion__1xo1j {
  font-size: 1.25rem;
}
.RunbookSnapshotInfo_dataTableHeaderColumn__24eDv,
.RunbookSnapshotInfo_dataTableRowColumn__ZyHCq {
  max-width: 16rem;
}
.RunbookSnapshotInfo_dataTableRowColumn__ZyHCq:first-child {
  width: 9.375rem;
}
.RunbookSnapshotInfo_dataTableRowColumn__ZyHCq:not(:first-child) {
  width: 16rem;
}
@media (max-width: 37.4rem) {
  .RunbookSnapshotInfo_releaseDetailsLayout__2U_sC {
    flex-direction: column;
  }
  .RunbookSnapshotInfo_releaseDetailsSidebarContent__19iwa {
    max-width: 100%;
  }
  .RunbookSnapshotInfo_subTitle__35F7- {
    padding-left: 1rem;
  }
  .RunbookSnapshotInfo_marginTopBottom__1wVwp {
    margin: 0.5rem 0;
  }
}
.RunbookSnapshotInfo_releaseNoteLayout__2DS6a,
.RunbookSnapshotInfo_releaseArtifactsLayout__2kSOJ,
.RunbookSnapshotInfo_releaseBuildInformationLayout__2_R8L {
  font-size: 0.875rem;
}
.RunbookSnapshotInfo_releasePackagesLayout__2JDlv {
  font-size: 0.875rem;
}
.RunbookSnapshotInfo_releasePackagesLayout__2JDlv p {
  margin: 0.5rem !important;
}
.RunbookSnapshotInfo_releasesTableContainer__dLPU2 .RunbookSnapshotInfo_filter__rL5UO {
  margin-left: 1rem;
  max-width: 16.25rem;
}
.RunbookSnapshotInfo_releasesTableContainer__dLPU2 .RunbookSnapshotInfo_releaseNotesContainer__1Y00Q {
  cursor: auto;
}
.RunbookSnapshotInfo_releasesTableContainer__dLPU2 .RunbookSnapshotInfo_collapseButton__LMGhw {
  margin-left: -0.5rem;
}
.RunbookSnapshotInfo_releasesTableContainer__dLPU2 .RunbookSnapshotInfo_notesColumn___9zmt {
  width: 40rem;
}
.RunbookSnapshotInfo_deployButtonFilterContainer__s3yYH {
  margin: 0 1rem;
}
.RunbookSnapshotInfo_deployButtonFilterContainer__s3yYH .RunbookSnapshotInfo_filterFieldContainer__ju2iF {
  width: 100%;
}
.RunbookSnapshotInfo_deployButtonFilterContainer__s3yYH div {
  color: white !important;
}
.RunbookSnapshotInfo_deployButtonFilterContainer__s3yYH input {
  color: white !important;
}
.RunbookSnapshotInfo_deployButtonFilterContainer__s3yYH hr:last-child {
  border-color: white !important;
}
.RunbookSnapshotInfo_artifactTime__2xVL0 {
  margin-left: 1rem;
  display: inline-block;
}
