@media (max-width: 37.4rem) {
  .DeploymentPartForSidebar_hide-sm__17R4k {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .DeploymentPartForSidebar_visible-sm__2rGDR {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .DeploymentPartForSidebar_hide-md__3i5H4 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .DeploymentPartForSidebar_visible-md__2Yluj {
    display: none !important;
  }
}
.DeploymentPartForSidebar_stepList__8ATW2 {
  display: flex;
  flex-direction: column;
}
.DeploymentPartForSidebar_stepList__8ATW2 > :last-child > .DeploymentPartForSidebar_listItemLine__1KWvt,
.DeploymentPartForSidebar_stepList__8ATW2 > :last-child > .DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_listItemLine__1KWvt {
  display: none;
}
.DeploymentPartForSidebar_stepList__8ATW2 > :last-child .DeploymentPartForSidebar_stepContainer__3OVaw {
  border-bottom: unset;
}
.DeploymentPartForSidebar_listItem__39aLz {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 0.5rem 0;
  padding: 0;
  position: relative;
  overflow: visible;
  z-index: 0;
  transition: all 200ms ease;
  min-height: 2rem;
  background-color: transparent;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0);
  max-width: 17.5rem;
  border-top: 0.0625rem solid transparent;
  border-bottom: 0.0625rem solid transparent;
  overflow-wrap: break-word;
}
@media (max-width: 37.4rem) {
  .DeploymentPartForSidebar_listItem__39aLz {
    max-width: unset;
  }
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_listItemLine__1KWvt {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  height: 100%;
  border-left: 0.0625rem solid #666666;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepContainer__3OVaw {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5rem 0;
  margin-left: 0.5rem;
  width: 100%;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepContainer__3OVaw .DeploymentPartForSidebar_stepNumber__1gS0c {
  font-weight: 600;
  color: #333333;
  margin: 0 0.25rem 0 0;
  font-size: 0.875rem;
  line-height: 1rem;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepContainer__3OVaw .DeploymentPartForSidebar_stepName__25el5 {
  color: #333333;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  max-width: 11rem;
}
@media (max-width: 37.4rem) {
  .DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepContainer__3OVaw .DeploymentPartForSidebar_stepName__25el5 {
    max-width: 16rem;
  }
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_contextualActions__2V5ix {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_contextualActionsDisabledFakeMenuIcon__EoI1I {
  opacity: 0.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 2.25rem;
  cursor: default;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepLogo__19wCA {
  width: 2rem;
  height: 2rem;
  z-index: 1;
  flex-shrink: 0;
  border: 0.0625rem solid #cccccc;
  border-radius: 2rem;
  justify-content: center;
  display: flex;
  padding-left: 0.0625rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepLogo__19wCA svg {
  padding-left: 0.0625rem;
  width: auto;
  height: 60%;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepLogo__19wCA img {
  width: 1.4rem;
  border-radius: 1.4rem;
  height: auto;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_parallelContainer__MFzwC {
  padding: 0.25rem;
  border-radius: 0.625rem;
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 1rem;
  height: 1rem;
  background: #cccccc;
  align-items: center;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_parallelIcon___HIlm {
  width: 0.75rem;
  height: 0.375rem;
  border-top: 0.125rem solid #333333;
  border-bottom: 0.125rem solid #333333;
}
.DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_parallelIcon___HIlm .DeploymentPartForSidebar_content__1VlVi {
  width: 100%;
  height: 100%;
  display: block;
}
.DeploymentPartForSidebar_listItem__39aLz:hover {
  background-color: rgba(153, 153, 153, 0.08);
}
.DeploymentPartForSidebar_listItem__39aLz:hover .DeploymentPartForSidebar_stepContainer__3OVaw .DeploymentPartForSidebar_stepNumber__1gS0c,
.DeploymentPartForSidebar_listItem__39aLz:hover .DeploymentPartForSidebar_stepContainer__3OVaw .DeploymentPartForSidebar_stepName__25el5 {
  color: #0A67B1;
}
.DeploymentPartForSidebar_listItem__39aLz.DeploymentPartForSidebar_isActive__2DRUV .DeploymentPartForSidebar_stepName__25el5 {
  color: #2f93e0;
  font-weight: 600;
}
.DeploymentPartForSidebar_isDisabled__2kHxj * {
  color: #9e9e9e !important;
}
.DeploymentPartForSidebar_group__15zZE > .DeploymentPartForSidebar_listItem__39aLz {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
  border-bottom: 0.0625rem solid transparent;
  border-top: 0.0625rem solid transparent;
  align-items: stretch;
}
.DeploymentPartForSidebar_group__15zZE > .DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepName__25el5,
.DeploymentPartForSidebar_group__15zZE > .DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepNumber__1gS0c {
  font-size: 0.75rem;
}
.DeploymentPartForSidebar_group__15zZE > .DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepLogo__19wCA {
  border-radius: 0;
  margin-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  align-items: center;
}
.DeploymentPartForSidebar_group__15zZE > .DeploymentPartForSidebar_listItem__39aLz .DeploymentPartForSidebar_stepContainer__3OVaw {
  line-height: 1.25rem;
  border-bottom: 0.0625rem solid transparent;
}
.DeploymentPartForSidebar_group__15zZE > a:first-child {
  margin-bottom: 0;
  margin-top: 0;
}
.DeploymentPartForSidebar_group__15zZE > a:first-child .DeploymentPartForSidebar_stepName__25el5,
.DeploymentPartForSidebar_group__15zZE > a:first-child .DeploymentPartForSidebar_stepNumber__1gS0c {
  font-size: 0.875rem;
}
.DeploymentPartForSidebar_group__15zZE > a:first-child .DeploymentPartForSidebar_stepLogo__19wCA {
  border-bottom: 0 solid transparent;
  background-color: #0A67B1;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  justify-content: center;
}
.DeploymentPartForSidebar_group__15zZE > a:first-child .DeploymentPartForSidebar_stepLogo__19wCA svg {
  fill: #ffffff;
  width: auto;
  height: 100%;
  padding-left: 0;
}
.DeploymentPartForSidebar_group__15zZE > a:first-child:hover svg {
  fill: #0A67B1;
}
.DeploymentPartForSidebar_group__15zZE > a:last-child {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.DeploymentPartForSidebar_group__15zZE > a:last-child .DeploymentPartForSidebar_stepLogo__19wCA {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  flex: 1;
}
.DeploymentPartForSidebar_stepWrapper__1PE3V {
  display: flex;
  flex-direction: column;
}
.DeploymentPartForSidebar_stepWrapper__1PE3V div:nth-child(2) {
  flex: 1;
}
