.RunbookOverviewLayout_cardContainer__2a8Zq:hover {
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12);
}
.RunbookOverviewLayout_cardContainer__2a8Zq div {
  max-height: none;
}
.RunbookOverviewLayout_dashboardCards__3KE-D {
  width: auto;
}
.RunbookOverviewLayout_headerRow__pXy8O {
  border: unset;
}
.RunbookOverviewLayout_bodyRow__3Ynn_ {
  border: unset;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.RunbookOverviewLayout_bodyRow__3Ynn_:last-child {
  border-bottom: none;
}
.RunbookOverviewLayout_card__3PO-S {
  text-align: left;
  padding: 0 0 0 0.25rem;
  margin: 1rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-basis: 30%;
}
.RunbookOverviewLayout_runTaskDetails__NJ4Gc {
  display: flex;
  flex-direction: row;
}
.RunbookOverviewLayout_runTaskEnvironment__3PYL9 {
  padding: 0 0.5rem 0.25rem;
  font-weight: 600;
  font-size: 1.0625rem;
}
.RunbookOverviewLayout_runTaskNotes__2Kb9r {
  padding: 0.5rem;
  color: #666666;
  font-weight: 400;
  font-size: 0.6875rem;
  background-color: #fafafa;
  overflow: auto;
}
.RunbookOverviewLayout_environmentName__oKT3G,
.RunbookOverviewLayout_tenantName__30wN0 {
  font-weight: 600;
  font-size: 1.0625rem;
}
.RunbookOverviewLayout_rowHeader__3KJrE {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: left;
  font-weight: 500;
}
.RunbookOverviewLayout_groupHeader__Nu4NZ {
  display: flex;
  align-items: center;
  background: rgba(153, 153, 153, 0.08);
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.5rem 0;
  font-size: 1.0625rem;
  justify-content: space-between;
}
.RunbookOverviewLayout_tileName__3bv6D {
  padding-left: 0.25rem;
  font-size: 1rem;
}
.RunbookOverviewLayout_runActions__2_3HJ {
  padding-left: 0;
}
.RunbookOverviewLayout_emptyCell__fP_R6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem;
  font-size: 0.875rem;
}
