.OperationsOverviewLayout_headerColumn__1g2Fg {
  width: 33.333%;
}
.OperationsOverviewLayout_headerColumnTenanted__3yidn {
  width: 25%;
}
.OperationsOverviewLayout_emptyCell__3w8-d {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 0.875rem;
}
